import faTelegram from 'assets/images/faTelegram.svg';
import faFacebook from 'assets/images/faFacebook.svg';

function Footer() {
  return (
    <footer className="text-white">
      <div className="w-full bg-mka-primary-100">
        <div className="container px-4 md:px-0 mx-auto pt-16">
          <div className="pb-10 text-sm leading-6">
            <div className="md:flex w-full justify-between">
              <div className="mb-5 md:mb-0">
                <h3 className="font-semibold text-lg text-slate-900 dark:text-slate-100">
                  Tài liệu
                </h3>
                <ul className="mt-3 space-y-1">
                  <li>
                    <a
                      className="text-white hover:text-slate-900 dark:hover:text-slate-300"
                      href="/files/white-paper.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      White paper
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mb-5 md:mb-0">
                <h3 className="font-semibold text-lg text-slate-900 dark:text-slate-100">
                  Điều khoản
                </h3>
                <ul className="mt-3 space-y-1">
                  <li>
                    <a
                      className="text-white hover:text-slate-900 dark:hover:text-slate-300"
                      href="/files/term-condition.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Điều khoản dịch vụ
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-white hover:text-slate-900 dark:hover:text-slate-300"
                      href="/files/privacy-policy.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Chính sách bảo mật
                    </a>
                  </li>
                </ul>
              </div>
              <div className="min-w-[200px] mb-5 md:mb-0">
                <h3 className="font-semibold text-lg text-slate-900 dark:text-slate-100">
                  Cộng đồng
                </h3>
                <div className="flex gap-2 mt-3">
                  <div>
                    <a href="/">
                      <img
                        src={faTelegram}
                        alt="telegram"
                        className="h-8"
                      />
                    </a>
                  </div>
                  <div>
                    <a href="/">
                      <img
                        src={faFacebook}
                        alt="facebook"
                        className="h-8"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" px-4 md:px-0 mx-auto py-2 text-sm">
            <span>© 2022 BOOK Limited. All rights reserved.</span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
